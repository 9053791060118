import { Injectable, OnInit } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { map } from 'rxjs/operators/map';
import { catchError } from 'rxjs/operators/catchError';
import { Observable } from 'rxjs/Observable';
import { SharedDataService } from '../../@core/data/shared-data.service';
import { LoginService } from '../../@core/data/login.service';
import { Staff } from '../../@core/model/staff.model';


@Injectable()
export class AuthGuardService implements CanActivate {

  loggedUser: Staff;

  constructor(private sharedDataService: SharedDataService,
    private router: Router,
    private cookieService: CookieService,
    private loginService: LoginService) {
      this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser;});
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.loggedUser) {
      return Observable.of(true);
    } else {

        if(this.loginService.hasCookie()){
          this.loginService.getLoggedUser().subscribe(data =>{
          }, error => {console.log(error)});
          return Observable.of(true)

      } else {
          this.router.navigate(['auth/login/simple'], {
          });
          return Observable.of(false);
          }
      }

}
}
