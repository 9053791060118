import { LoginService } from './@core/data/login.service';
import { CookieService } from 'ngx-cookie-service';
import { CoreModule } from './@core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { AuthModule } from './theme/auth/auth.module';
import { AgmCoreModule } from '../../node_modules/@agm/core';
import { NgbModule } from '../../node_modules/@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { PapaParseModule } from 'ngx-papaparse';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    PapaParseModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    AuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCwp6foLemGuX1UdShN1qPAGrwlYVJSP_w'
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    NgbModule.forRoot()
  ],
  providers: [MenuItems],
  bootstrap: [AppComponent]
})
export class AppModule { }
