import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Admin Area',
    main: [
      {
        state: 'registrations',
        short_label: 'R',
        name: 'Registrazioni',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'codes',
        short_label: 'I',
        name: 'Iscritti',
        type: 'link',
        icon: 'ti-paint-roller'
      },
    ],
  },
 
  /*{
    label: 'Anagrafica',
    main: [
      {
        state: 'utenti',
        short_label: 'a',
        name: 'Utenti',
        type: 'sub',
        icon: 'ti-user',
        children: [
          {
            state: 'utenti',
            name: 'Utenti'
          },
          {
            state: 'nuovoutente',
            name: 'Nuovo Utente'
          },
        ]

      },
      {
        state: 'newsletter',
        short_label: 'A',
        name: 'Newsletter',
        type: 'link',
        icon: 'ti-envelope'
      },
      {
        state: 'b2b',
        short_label: 'A',
        name: 'B2B',
        type: 'link',
        icon: 'ti-briefcase'
      }
    ]
  },*/
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
