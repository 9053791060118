import { Code } from './code.model';
import { Type, Transform } from 'class-transformer/decorators';
export class Registration {
    _id: string;
    created: Date;
    fname: string;
    lname: string;
    email: string;
    hosts: number;

    @Transform(hostnamesToPlain, { toPlainOnly: true })
    @Transform(hostnamesToClass, { toClassOnly: true })
    hostnames: Hostname[];

    code: string;

    phone: string;
    address: string;
    city: string;
    province: string;
    @Type(code)
    usercode: Code;


    getCsvData() {
        return {fname: this.fname, lname: this.lname, email: this.email, hostsn: this.hosts, hostlist: this.getHostList() };
    }


    getHostList() {
        let l = '';
        if (this.hostnames) {
            this.hostnames.forEach(element => {
            l = l + element.name + ',';
        });
        }
        return l;
    }

    addHost(){
        if(!this.hostnames){
            this.hostnames = [];
        }
        this.hostnames.push(new Hostname(''));
    }

    removeHost(index: number){
        if(this.hostnames && this.hostnames.length > index){
            this.hostnames.splice(index, 1);
        }
    }

}

export class Hostname{
    name: string;

    constructor(name: string){
        this.name = name;
    }
}


function hostnamesToPlain(value: Hostname[]): string[]{
    const names = [];
    value.forEach(element => {
        names.push(element.name);
    });
    return names;
}

function hostnamesToClass(value: string[]){
    const _hostnames = [];

    value.forEach(element => {
        _hostnames.push(new Hostname(element));
    });
    return _hostnames;

}

function code() {
    return Code;
}
