import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SharedDataService } from './shared-data.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';
import 'rxjs/add/operator/map';
import { Staff } from '../model/staff.model';

@Injectable()
export class UserService {
 
  httpOptions: any;
  loggedUser: Staff;

  constructor(protected http: HttpClient,
    private sharedDataService: SharedDataService,
    private cookieService: CookieService ,
  private loginService: LoginService) {

   const token = loginService.getToken();
    this.httpOptions = {
      headers: new HttpHeaders(
        {'x-token': token}
      ),
    };
    this.sharedDataService.currentLoggedUser.subscribe(user => this.loggedUser = user);
  }


}
