import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import {SharedModule} from '../../shared/shared.module';
import { AuthGuardService } from './auth-guard.service';
import { SharedDataService } from '../../@core/data/shared-data.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';
import { LoginService } from '../../@core/data/login.service';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  declarations: [],
  providers: [
    AuthGuardService,
    SharedDataService,
    CookieService,
    LoginService
  ]
})
export class AuthModule { }
