import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';

import { Observable } from 'rxjs';
import { Registration } from '../model/registration.model';
import { map } from 'rxjs/operators';
import { SharedDataService } from './shared-data.service';
import { Counts } from '../model/counts.model';

@Injectable()
export class RegistrationService {

    httpOptions: any;
  token: string;

    constructor(protected http: HttpClient, 
        private sharedDataService: SharedDataService) {
        this.sharedDataService.currentToken.subscribe(token => {
            console.log('token',token);
            if(token){
            this.token = token;
            this.httpOptions = {
              headers: new HttpHeaders(
                {'x-token': token}
              ),
            };
            }
          })
    }
    
    getCounts(): Observable<Counts> {
      return this.http.get(`/evs/registration/counts`)
      .pipe(map(data => plainToClass(Counts, data as Object)));
  }

    getRegistrations(): Observable<any> {
        return this.http.get(`/evs/registration`,this.httpOptions)
        .pipe(map(data => plainToClass(Registration, data as Object)));
      }

    saveRegistration(r: Registration): Observable<Registration> {
        return this.http.post(`/evs/registration`, r,this.httpOptions )
        .pipe(map(data => plainToClass(Registration, data as Object)));
    }


    deleteRegistration(r: Registration): Observable<any> {
        return this.http.delete(`/evs/registration/${r._id}`,this.httpOptions);
        
    }

    confirmRegistration(token: string): Observable<any> {
      return this.http.post(`/evs/registration/confirmtoken/`, {token: token});
  }

  updateRegistration(r: Registration): Observable<any> {
    return this.http.put(`/evs/registration/${r._id}`, classToPlain(r), this.httpOptions);
}

}
