import { Md5 } from 'ts-md5/dist/md5';

export class Staff{
    token: string;
    _id: string;
    password: any;
email: string;

    set md5password(pass: string) {
		this.password = Md5.hashStr(pass);
	}
}