import { SharedDataService } from './shared-data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Staff } from '../model/staff.model';

@Injectable()
export class LoginService {

  token: string;
  tokenCookie = 'EVENTSAdminToken';
  userCookie = 'EVENTSAdminUser';

  constructor(protected http: HttpClient,
    private cookieService: CookieService,
    private sharedDataService: SharedDataService) {

    sharedDataService.currentToken.subscribe(token => { this.token = token; });
  }

  login(login: Staff) {
    const httpOptions = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post('/evs/staff/login', login, httpOptions)
      .map(res => {
        const s: Staff = plainToClass(Staff, res as Object);
        this.sharedDataService.setLoggedUser(s);
        this.cookieService.set(this.tokenCookie, s.token);
        this.cookieService.set(this.userCookie, s._id + '');
        return s;
      });
    // return Observable.of(new User('test', 'test')); mock login
  }


  logout() {
    this.cookieService.delete(this.tokenCookie);
    this.cookieService.delete(this.userCookie);
    this.sharedDataService.setLoggedUser(undefined);
  }

  hasCookie() {
    return this.cookieService.get(this.tokenCookie) && this.cookieService.get(this.userCookie);
  }
  getToken() {
    return this.cookieService.get(this.tokenCookie);
  }
  getLoggedUser() {
  
    const token = this.cookieService.get(this.tokenCookie);
    const user = this.cookieService.get(this.userCookie);
    this.sharedDataService.setToken(token);
    if (token && user) {
      return this.getUser(user).map(data => { 
        this.token = data.token; 
        this.sharedDataService.setLoggedUser(data);
        return data; }
      );
    }
    return Observable.of(undefined);
  }



  getUser(user: string): Observable<Staff> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': this.token
      }),
    };
     return this.http.get(`/evs/staff/${user}`, httpOptions)
      .map(res => plainToClass(Staff, res as Object));
   // return Observable.of(new Staff()); //mock user endpoint

  }

}

