import { RegistrationService } from './registration.service';
import { SharedDataService } from './shared-data.service';
import { UserService } from './user.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrudService } from './crud.service';
import { CodeService } from './code.service';


const SERVICES = [
  UserService,
  SharedDataService,
  CrudService,
  RegistrationService,
  CodeService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
