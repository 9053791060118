import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { plainToClass } from 'class-transformer';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedDataService } from './shared-data.service';
import { Code } from '../model/code.model';

@Injectable()
export class CodeService {

    httpOptions: any;
  token: string;

    constructor(protected http: HttpClient, 
        private sharedDataService: SharedDataService) {
        this.sharedDataService.currentToken.subscribe(token => {
            console.log('token',token);
            if(token){
            this.token = token;
            this.httpOptions = {
              headers: new HttpHeaders(
                {'x-token': token}
              ),
            };
            }
          })
    }
    

    getCodes(): Observable<any> {
        return this.http.get(`/evs/code`,this.httpOptions)
        .pipe(map(data => plainToClass(Code, data as Object)));
      }

    saveCodes(codes: Code[]): Observable<any> {
        return this.http.post(`/evs/code/batchsave`, codes,this.httpOptions )
        .pipe(map(data => plainToClass(Code, data as Object)));
    }


}
