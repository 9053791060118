import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/of';

import { Staff } from '../model/staff.model';

@Injectable()
export class SharedDataService {
    private loggedUser= new BehaviorSubject<Staff>(undefined);
    currentLoggedUser = this.loggedUser.asObservable();

    private token = new BehaviorSubject<string>(undefined);
    currentToken = this.token.asObservable();

  

    private action = new BehaviorSubject<any>(undefined);
    currentAction = this.action.asObservable();

    constructor() {
    }

    setLoggedUser(u: Staff) {
        this.loggedUser.next(u);
    }

    setToken(t: string){
        this.token.next(t);
    }

    removeLoggedUser() {
        this.loggedUser.next(undefined);
    }

    removeToken(){
        this.token.next(undefined);
    }

    sendAction(action, params) {
        this.action.next({action: action, params: params});
    }

}
